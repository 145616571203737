import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { timeFormat } from "../../components/tools.js";
import CalendarHeader from "../../components/CalendarHeader/CalendarHeader.js";
import { useNavigate } from "react-router-dom";
import { DateTime } from 'luxon';
import AdminPanel from "../../components/AdminPanel/AdminPanel";
import ClientMenu from "../../components/ClientMenu/ClientMenu";
import ClientMainButton from "../../components/ClientMainButton/ClientMainButton.js";
import { GetTemplate } from "../../components/DialogTemplates/GetTemplate.js";
import { PopUpAlert } from "../../components/DialogTemplates/PopUpAlert.js";



import { AppContext } from "../../App.js";

const ClientCalendar = (props) => {

    const [appData, setAppContext] = useContext(AppContext);

    const navigate = useNavigate();
    const [records, setRecords] = useState();
    const [calendar, setCalendar] = useState();
    const [recordDate, setRecordDate] = useState(false);

    const DateTimeServerNow = () => {
        return DateTime.now().plus({ milliseconds: appData.time_zone_delta });
    }

    const [calendarDate, setCalendarDate] = useState(DateTimeServerNow());

    useEffect(() => {
        console.log("useEffect handleBackButton");

        const handleBackButton = () => {
            navigate(-1);
        }

        window?.Telegram.WebApp.BackButton.show();
        window?.Telegram.WebApp.BackButton.onClick(handleBackButton);

        return () => {
            window?.Telegram.WebApp.BackButton.hide();
            window?.Telegram.WebApp.BackButton.offClick(handleBackButton);
        };

    }, []);

    const handleCalendar = (date) => {
        setRecordDate(false);
        setCalendarDate(date);
    };

    const handleSaveRecord = (item) => {

        axios.post("/api/client_v1/records/", {
            data: { record: { ...appData.basket, start: recordDate }, client_guid: appData?.basket?.selected_client?.guid }
        }).then((result) => {
            if (result?.data?.error) {
                window.Telegram.WebApp.showAlert(result?.data?.error);
                setCalendar(result.data);
            } else {
                //Запись прошла успешно
                let link;
                let timeText = recordDate.setLocale("ru").toFormat("d MMMM на H:mm");

                if (appData?.basket?.selected_client?.guid) {
                    //msgText = "Клиент: " + appData.basket?.selected_client.name + " записан " + timeText;
                    PopUpAlert("PopUpCalendarStatus-recorded-by-master", timeText, appData.basket?.selected_client.name);

                    link = "/app/master/tenant/calendar/" + recordDate.toISODate() + "/";
                } else {

                    PopUpAlert("PopUpCalendarStatus-recorded", timeText);
                    link = "/app/master/client/records/";
                }

                setAppContext({ ...appData, "basket": {} });
                navigate(link);

            }
        });
    };

    useEffect(() => {
        const controller = new AbortController();

        if (calendarDate) {
            setCalendar(false);
            axios.get("/api/client_v1/calendar/" + calendarDate.toFormat("yyyy-MM-dd") + "/", {
                signal: controller.signal
            }).then((result) => {
                setCalendar(result.data);
            });
        }

        return () => {
            controller.abort();
        };
    }, [calendarDate]);



    useEffect(() => {

        if (calendar?.client_records && calendar?.work_schedule.length > 0) {

            let key = 0;
            let result = [];

            //Период от начала до окончания заполняем как Перерыв у мастера
            let startTime = DateTime.fromISO(calendar.work_schedule[0].start);
            let endTime = DateTime.fromISO(calendar.work_schedule.at(-1).end);

            for (let time = startTime; time < endTime; time = time.plus({ minutes: calendar?.period })) {
                result[time.valueOf()] = { index: time.valueOf(), start: time, end: time.plus({ minutes: calendar?.period }), type: "break", key: key, text: "Перерыв у мастера" }; // text: "Перерыв" 
            }


            //Сверху накладываем расписание мастера
            calendar?.work_schedule.forEach((period, index) => {
                let startTime = DateTime.fromISO(period.start);
                let endTime = DateTime.fromISO(period.end);
                key++;
                for (let time = startTime; time < endTime; time = time.plus({ minutes: calendar?.period })) {
                    result[time.valueOf()] = { index: time.valueOf(), start: time, end: time.plus({ minutes: calendar?.period }), type: "plan", key: key, text: "Свободно" };
                }
            });

            //Сверху накладываем записи клиентов
            calendar?.client_records.forEach(period => {
                let startTime = DateTime.fromISO(period.start);
                let endTime = DateTime.fromISO(period.end);
                key++;
                for (let time = startTime; time < endTime; time = time.plus({ minutes: calendar?.period })) {
                    result[time.valueOf()] = { index: time.valueOf(), start: time, end: time.plus({ minutes: calendar?.period }), type: "used", key: key, guid: period.guid, text: "Занято" };
                }
            });

            //Ищем периоды подходящей длительности
            let masterTimeNow = DateTimeServerNow();
            startTime = DateTime.fromISO(calendar.work_schedule[0].start);
            endTime = DateTime.fromISO(calendar.work_schedule.at(-1).end);

            for (let time = startTime; time < endTime; time = time.plus({ minutes: calendar?.period })) {

                //удаляем уже прошедшее время
                if (time < masterTimeNow) {
                    delete result[time.valueOf()];
                    continue;
                };

                //период свободный
                if (result[time.valueOf()].type === "plan") {
                    let startCheckTime = time;
                    let endCheckTime = time.plus({ minutes: appData.basket.duration });
                    let check = true;
                    let period_duration = 0;

                    for (let checkTime = startCheckTime; checkTime < endCheckTime; checkTime = checkTime.plus({ minutes: calendar?.period })) {

                        if (result[checkTime.valueOf()]?.type !== "plan") {
                            check = false;
                            break;
                        }
                        period_duration += calendar?.period;
                    }

                    if (check) {
                        result[time.valueOf()].key = ++key;
                        result[time.valueOf()].type = "free";
                        result[time.valueOf()].text = "Cвободно";
                    } else {
                        //result[time.valueOf()].key = ++key;
                        result[time.valueOf()].period_duration = period_duration;
                        result[time.valueOf()].type = "short";
                        result[time.valueOf()].text = "Частично свободно";
                    }
                }
            }


            // переиндексируем
            let result_sort = [];
            for (let index in result) {
                result_sort.push(result[index]);
            }

            // сортируем
            result_sort = result_sort.sort((a, b) => a.index - b.index);

            //группируем однотипные периоды
            //let group = { periods: [] };
            let result_group = [];

            result_sort.forEach((item) => {
                result_group.push({ ...item, periods: [item.start] });
            });

            setRecords(result_group);
        } else {
            setRecords();
        }

    }, [calendar]);


    const handleCalendarClick = (record) => {
        let msgCode = record?.type;
        // let msgList = {
        //     short: "Свободно " + + " этого времени недостаточно для записи, уменьшите количество услуг!",
        //     record: "Это время уже занято, попробуйте поискать свободное!",
        //     break: "Это время уже занято, попробуйте поискать свободное!"
        // };

        // let WebApp = window.Telegram.WebApp;

        if (record.start < DateTimeServerNow()) {
            msgCode = "offside";
        }

        if (msgCode) {
            GetTemplate((template) =>
                window?.Telegram?.WebApp.showAlert(template),
                "PopUpCalendarStatus-" + msgCode,
                timeFormat(record.period_duration)
            );

        }

        if (record.type === "free") {
            setRecordDate(record.start);
        }

        if (record.type === "short") {
            setRecordDate();
        }

    }

    return (
        <>

            <AdminPanel />

            <Container className="mt-3 client" style={{ marginBottom: "110px" }}>
                <ClientMenu />

                <Row >
                    <Col className="text-center"><h1 className="mt-3 mb-3">Выберите дату и время</h1></Col>
                </Row>

                <CalendarHeader calendarMinDate={DateTimeServerNow()} calendarDate={calendarDate} handleUpdateCalendarDate={handleCalendar} />

                {calendar ? (
                    <>
                        {calendar?.work_schedule?.length === 0 && (
                            <h1 className="mt-4 mb-4">Расписание не заполнено</h1>
                        )}

                        {calendar?.work_schedule?.length > 0 && (
                            <Row >
                                <Col className="text-center">
                                    <h3>
                                        {"Мастер работает с "}
                                        {DateTime.fromISO(calendar.work_schedule[0].start).toFormat("H:mm")}
                                        {" до "}
                                        {DateTime.fromISO(calendar.work_schedule.at(-1).end).toFormat("H:mm")}
                                    </h3>
                                </Col>
                            </Row>
                        )}

                        {records && (
                            <div className="calendar">
                                {records?.map((record, index) => (
                                    <div
                                        key={index}
                                        className={"item " + record.type + (record.start === recordDate ? " selected" : "")}
                                        onClick={() => handleCalendarClick(record)} >
                                        <Row className="p-2">
                                            <Col className="col-2 pe-0">
                                                <span className="period">
                                                    {record?.periods.map((time) => DateTime.fromISO(time).toFormat("H:mm"))}
                                                </span>
                                            </Col >
                                            <Col className="col-10">
                                                {record?.text}
                                            </Col >
                                        </Row>
                                    </div>
                                ))}
                            </div>
                        )}

                    </>
                ) : (
                    <Row className="vh-100 text-center">
                        <Col className="mt-5">
                            <Spinner animation="border" variant="secondary" />
                        </Col>
                    </Row>
                )}
            </Container >

            <ClientMainButton
                mainButtonText={recordDate ? "Записаться" : "Выберите дату и время"}
                disabled={!recordDate}
                handleMainButtonClick={handleSaveRecord}
            />

        </>
    )
};

export default ClientCalendar;
