import axios from "axios";
import { useEffect, useContext } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Container, Col, Row } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import { Settings, DateTime } from 'luxon'

var tgauth = false;

const TelegramAuth = (props) => {

  const [appContext, setAppContext] = useContext(AppContext);

  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const WebApp = window.Telegram.WebApp;
    if (WebApp?.initData && !tgauth) {
      tgauth = true;
      WebApp.expand();
      WebApp.disableVerticalSwipes();
      var auth = {
        init_data: WebApp.initData,
        guid: params.guid, // уникальный идентификатор Бота
      };
      axios.post("/api/telegram/tgauth/", auth).then((result) => {
        axios.defaults.headers.common['Authorization-Token'] = result?.data?.token;
        Settings.defaultZone = result?.data?.auth_context.time_zone_utc;
        let time_zone_delta = DateTime.fromISO(result?.data?.auth_context.time_stamp).diff(DateTime.now()).milliseconds;
        setAppContext({ ...result?.data?.auth_context, time_zone_delta: time_zone_delta, guid: params.guid, "basket": {} });
        navigate(result?.data?.location);
        //запрос номера
        if (result?.data?.auth_context?.request_contact) {
          WebApp.requestContact();
        }
      });
    }
  }, []);

  return (
    <Container>
      <Row className="vh-100 text-center">
        <Col className="m-auto">
          <Spinner animation="border" variant="secondary" />
        </Col>
      </Row>
    </Container>
  );
};

export default TelegramAuth;
