import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AvatarEditor from "react-avatar-editor";
import { Container, Row, Col, Form, Spinner, Image } from 'react-bootstrap';
import AdminPanel from "../../components/AdminPanel/AdminPanel.js";
import AdminMenu from "../../components/AdminMenu/AdminMenu.js";
import DialigConfirm from "../../components/DialigConfirm/DialigConfirm.js";
import { IconAddBigPhoto } from "../../components/Icons.js";
import { useForm } from "react-hook-form";
import "./Style.css";

const AdminItem = (props) => {

  const { guid } = useParams();
  const { register, handleSubmit } = useForm();
  const cropRef = useRef(null);
  const navigate = useNavigate();
  const [catalogItem, setCatalogItem] = useState();
  //const [formValue, setformValue] = useState({});
  const [submitVisible, setSubmitVisible] = useState(false);
  const [itemType, setItemType] = useState();
  const [image, setImage] = useState();
  const [scale, setScale] = useState(1.5);

  const handleScale = (event) => {
    setScale(event.target.value);
  }


  useEffect(() => {

    const handleBack = () => {
      navigate(-1);
    };

    window?.Telegram.WebApp.BackButton.show();
    window?.Telegram.WebApp.BackButton.onClick(handleBack);

    return () => {
      window?.Telegram.WebApp.BackButton.hide();
      window?.Telegram.WebApp.BackButton.offClick(handleBack);
    };

  }, [submitVisible]);


  useEffect(() => {

    if (["category", "item"].includes(guid)) {
      setItemType(guid);
    }

    if (catalogItem?.item && !catalogItem?.item?.category) {
      setItemType("item");
    }

    if (catalogItem?.item && catalogItem?.item?.category) {
      setItemType("category");
    }

  }, [guid, catalogItem]);


  const handleChange = () => {
    setSubmitVisible(true);
  }


  useEffect(() => {
    axios.get("/api/tenant_v1/catalog/item/" + guid).then((result) => {
      setCatalogItem(result?.data);
    });
  }, []);



  function resize(img, w, h) {
    var canvas = document.createElement('canvas');
    canvas.width = w;
    canvas.height = h;
    canvas.getContext('2d').drawImage(img, 0, 0, w, h);
    return canvas;
  }


  const onSave = (formValue) => {

    setSubmitVisible(false);


    function postImage(item_guid) {

      // if (cropRef && cropRef.current) {


      resize(cropRef.current.getImage(), 400, 400).toBlob((data) => {
        axios.post("/api/tenant_v1/image/" + item_guid, data, {
          headers: {
            "Content-Type": "image/webp"
          },
        }).then((result) => {
          navigate(-1);
        });
      }, 'image/webp', 0.75);
      //   }
    }

    if (["category", "item"].includes(guid)) {
      axios.put("/api/tenant_v1/catalog/item/" + guid, formValue).then((result) => {
        if (!(cropRef && cropRef.current)) {
          navigate(-1);
        } else {
          console.log("result.data", result.data);
          setCatalogItem(result?.data);
          postImage(result.data.item.guid);
        }
      });
    } else {
      axios.patch("/api/tenant_v1/catalog/item/" + guid, formValue).then((result) => {
        if (!(cropRef && cropRef.current)) {
          navigate(-1);
        } else {
          postImage(guid);
        }
      });

    };
  };

  const onImageChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
      setSubmitVisible(true);
    };
    reader.readAsDataURL(files[0]);
  };


  return (
    <>

      <AdminPanel />

      <AdminMenu title={itemType === "category" ? "Группа" : "Услуга"} />

      <Container className="admin-catalog-item">

        {!catalogItem && (
          <Row className="vh-100 text-center">
            <Col className="mt-5">
              <Spinner animation="border" variant="secondary" />
            </Col>
          </Row>
        )}

        {catalogItem && itemType === "item" && (
          <Form onChange={handleChange} >

            <Form.Group className="mt-2 mb-2 text-center" >
              <Form.Check // prettier-ignore
                type="switch"
                id="active"
                label="Показавать клиентам"
                reverse
                inline
                defaultChecked={catalogItem.item ? catalogItem?.item?.active : true}
                // name="active"
                {...register("active")}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Название услуги</Form.Label>
              <Form.Control as="textarea" rows={2} defaultValue={catalogItem?.item?.name}
                {...register("name", { required: true })}

              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="parent_guid">
              <Form.Label>Группа</Form.Label>
              <Form.Select defaultValue={catalogItem?.item?.parent_guid}
                {...register("parent_guid", { required: true })}
              >
                <option></option>
                {catalogItem?.parents?.map((parent) => (
                  <option key={parent.guid} value={parent.guid}>{parent.name}</option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Описание услуги</Form.Label>
              <Form.Control as="textarea" rows={4} defaultValue={catalogItem?.item?.description}
                {...register("description")}
              />
            </Form.Group>

            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="duration">
                  <Form.Label>Длительность, минут</Form.Label>
                  <Form.Control type="number" defaultValue={catalogItem?.item?.duration} placeholder="минуты"
                    {...register("duration", { min: 1, max: 600, required: true })}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="price">
                  <Form.Label>Стоимость, рублей</Form.Label>
                  <Form.Control type="number" defaultValue={catalogItem?.item?.price} placeholder="рубли"
                    {...register("price", { min: 1, max: 999999, required: true })}
                  />
                </Form.Group>
              </Col>

            </Row>

            < div className="text-center mb-3"  >
              {image ? (
                <>
                  <AvatarEditor
                    ref={cropRef}
                    image={image}
                    width={220}
                    height={220}
                    border={40}
                    borderRadius={15}
                    color={[155, 155, 155, 0.6]} // RGBA
                    scale={scale}
                    rotate={0}
                  />
                  <Form.Range className="mt-2" defaultValue={scale} min={0.5} max={3} step={0.01} onChange={handleScale} />
                </>
              ) : (
                catalogItem?.item?.image ? (
                  <Image className="w-75" src={"/api/client_v1/image/" + catalogItem?.item?.image} rounded fluid />
                ) : (
                  <label for="input_foto"><IconAddBigPhoto /></label>
                )
              )}
            </div>

            <label for="input_foto" className="btn btn-base w-100 mb-3">{catalogItem?.item?.image ? "Изменить фото" : "Выберите фото"}</label>
            <input type="file" accept="image/*;capture=camera" id="input_foto" className="d-none" onChange={onImageChange} />

            <DialigConfirm
              show={submitVisible}
              handleYes={handleSubmit(onSave)}
              handleCancel={() => navigate(-1)}
            />

          </Form>
        )}

        {catalogItem && itemType === "category" && (
          <Form onChange={handleChange} >

            <Form.Group className="mt-2 mb-2 text-center">
              <Form.Check
                type="switch"
                id="active"
                label="Показавать клиентам"
                reverse
                inline
                defaultChecked={catalogItem.item ? catalogItem?.item?.active : true}
                {...register("active")}

              />

            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Название группы</Form.Label>
              <Form.Control as="textarea" rows={2} defaultValue={catalogItem?.item?.name} name="name"
                {...register("name", { required: true })}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="sort">
              <Form.Label>Позиция в каталоге</Form.Label>
              <Form.Select defaultValue={catalogItem?.item?.sort} name="sort"
                {...register("sort")}
              >
                {[...Array((catalogItem?.parents?.length ?? 0) + 5)].map((parent, index) => (
                  <option key={index} value={index + 1}>{index + 1}</option>
                ))}
              </Form.Select>
            </Form.Group>


            <DialigConfirm
              show={submitVisible}
              handleYes={handleSubmit(onSave)}
              handleCancel={() => navigate(-1)}
            />

          </Form>
        )}
      </Container >
    </>
  );
};

export default AdminItem;
