import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { AppContext } from "../../App.js";
import { Container, Row, Col, Spinner, Button, Form } from 'react-bootstrap';
import AdminPanel from "../../components/AdminPanel/AdminPanel.js";
import ClientRecordsAccordion from "../ClientRecords/ClientRecordsAccordion.js"
import { useNavigate, useParams } from "react-router-dom";
import { IconСall, IconEdit, IconClient, IconСhat, IconWallet, IconHelp } from "../../components/Icons.js";
import DialigConfirm from "../../components/DialigConfirm/DialigConfirm.js";
import { useForm } from "react-hook-form";
import AdminMenu from "../../components/AdminMenu/AdminMenu.js";
import IconPopUpHelp from "../../components/DialogTemplates/IconPopUpHelp.js";


import "./Style.css";

const AdminClientItem = () => {


    const { guid } = useParams();
    const navigate = useNavigate();

    const [appData, setAppContext] = useContext(AppContext);

    const { register, handleSubmit, setFocus } = useForm();

    const [submitVisible, setSubmitVisible] = useState(false);
    const [clientData, setClientData] = useState()


    useEffect(() => {
        window.scrollTo(0, 0);
        const handleBackButton = () => {
            navigate(-1);
        }
        window?.Telegram.WebApp.BackButton.show();
        window?.Telegram.WebApp.BackButton.onClick(handleBackButton);

        return () => {
            window?.Telegram.WebApp.BackButton.hide();
            window?.Telegram.WebApp.BackButton.offClick(handleBackButton);
        };

    }, []);


    useEffect(() => {

        const controller = new AbortController();

        axios.get("/api/tenant_v1/clients/item/" + guid + "/", {
            signal: controller.signal
        }).then((result) => {
            setClientData(result.data);
        });

        return () => {
            controller.abort();
        };

    }, [guid]);


    const onSave = (data) => {
        axios.patch("/api/tenant_v1/clients/item/" + guid + "/", data
        ).then(() => {
            navigate(-1);
        });
    }

    const handleNewRecordClick = () => {
        setAppContext({ ...appData, "basket": { selected_client: clientData } });
        navigate("/app/master/client/");
    }

    return (
        <>
            <AdminPanel />
            <AdminMenu title="Клиент" />
            <Container className="admin admin-client-detail">
                {clientData ? (
                    <>
                        <h1 onClick={() => setFocus("name")} className="mb-3">
                            <IconClient /> {clientData.name ? clientData.name : "Новый клиент"} <IconEdit className="align-text-top" />
                        </h1>
                        <Row className="mb-3">


                            {clientData?.telegram_id > 0 && (
                                <Col className="col-12 mb-2 d-flex">

                                    <Form.Group className="record-phone flex-fill" >
                                        <Form.Control
                                            type="text"
                                            disabled={true}
                                            defaultValue={clientData.telegram_phone}
                                            className="client-phone"
                                        />
                                    </Form.Group>

                                    {/* <a href={"tel:" + clientData.telegram_phone} className="btn btn-base btn-box ms-1" target="_blank" rel="nofollow noreferrer noopener">
                                        <IconСall />
                                    </a>

                                    <a href={"https://t.me/" + clientData.telegram_phone + "?text=Здравствуйте, "} className="btn btn-base btn-box ms-1" target="_blank" rel="nofollow noreferrer noopener">
                                        <IconСhat />
                                    </a> */}

                                    <a href={"tel:" + clientData.telegram_phone} className={"btn btn-base btn-box ms-1" + (clientData.telegram_phone ? "" : " disabled")} target="_blank" rel="nofollow noreferrer noopener">
                                        <IconСall />
                                    </a>

                                    <a href={"https://t.me/" + clientData.telegram_phone + "?text=Здравствуйте, "} className={"btn btn-base btn-box ms-1" + (clientData.telegram_phone ? "" : " disabled")} target="_blank" rel="nofollow noreferrer noopener">
                                        <IconСhat />
                                    </a>

                                </Col>)}

                            <Col className="col-12 mb-3">
                                <Button className="w-100" onClick={handleNewRecordClick} variant="base">Записать клиента</Button>{' '}
                            </Col>


                            <Col className="col-8 record-name text-start">
                                <IconWallet className="me-1" />Баланс клиента
                            </Col>
                            <Col className="col-4  record-name text-end">
                                {clientData.client_balance} ₽
                            </Col>

                        </Row >

                        <Form onChange={() => setSubmitVisible(true)} >

                            {clientData?.telegram_id > 0 && (
                                <Form.Group className="mb-3">
                                    <Form.Label>Информация из телеграм</Form.Label>
                                    <Form.Control
                                        disabled={true}
                                        defaultValue={
                                            (clientData.telegram_first_name || "") + " "
                                            + (clientData.telegram_last_name || "") + " "
                                            + (clientData.telegram_username || "")
                                        }
                                    />
                                </Form.Group>
                            )}

                            <Form.Group className="mb-3">
                                <Form.Label>ФИО клиента</Form.Label>
                                <Form.Control
                                    defaultValue={clientData.name}
                                    {...register("name", { required: true })
                                    } />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Комментарий</Form.Label>
                                <Form.Control as="textarea" rows={2} defaultValue={clientData.description}  {...register("description")} />
                            </Form.Group>

                            <Form.Group className="mt-2 mb-3" >
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="black_list"
                                    label="Добавить в черный список"
                                    defaultChecked={clientData?.black_list}
                                    className="d-inline-block w-content"
                                    {...register("black_list")}
                                />
                                <IconPopUpHelp code="BlackListDescription" />
                            </Form.Group>


                            <DialigConfirm
                                show={submitVisible}
                                handleYes={handleSubmit(onSave)}
                                handleCancel={() => navigate(-1)}
                            />

                        </Form >

                        <h1 className="mb-3">
                            Записи клиента
                        </h1>

                        <ClientRecordsAccordion client_guid={guid} restoreRecord={true} />
                    </>
                ) : (
                    <Row className="vh-100 text-center">
                        <Col className="mt-5">
                            <Spinner animation="border" variant="secondary" />
                        </Col>
                    </Row >
                )}

            </Container >
        </>
    );
};
export default AdminClientItem;
