import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Spinner, Button } from 'react-bootstrap';
//import AdminCalendarList from "./AdminCalendarList.js";
//import AdminClientRecord from "./AdminClientRecord.js";
import { DateTime } from 'luxon'
import { AppContext, StatusName } from "../../App.js";
import { PopUpAlert } from "../../components/DialogTemplates/PopUpAlert.js";
import AdminPanel from "../../components/AdminPanel/AdminPanel.js";
import AdminMenu from "../../components/AdminMenu/AdminMenu.js";
import CalendarHeader from "../../components/CalendarHeader/CalendarHeader.js";
import { useNavigate, useParams } from "react-router-dom";
import AdminPeriodEditor from "./AdminPeriodEditor.js";
import "./Style.css";
import "./Icons.css";

const AdminCalendar = (props) => {

  const params = useParams();
  const [appData] = useContext(AppContext);
  const navigate = useNavigate();

  //  const [recordData, setRecordData] = useState();
  // const [recordGuid, setRecordGuid] = useState();
  const [showPeriodEditor, setShowPeriodEditor] = useState(false);
  const [calendarRecords, setCalendarRecords] = useState();
  const [calendar, setCalendar] = useState();


  const [calendarDate, setCalendarDate] = useState(
    params?.date ?
      DateTime.fromISO(params?.date)
      :
      DateTime.now().plus({ milliseconds: appData.time_zone_delta })
  );


  useEffect(() => {
    const controller = new AbortController();

    if (calendarDate) {
      //setCalendar(false);
      axios.get("/api/tenant_v1/calendar/" + calendarDate.toFormat("yyyy-MM-dd") + "/", {
        signal: controller.signal
      }).then((result) => {
        setCalendar(result.data);
        setCalendarRecords(PeriodsToRecords(result.data));
      });
    }

    return () => {
      controller.abort();
    };
  }, [calendarDate]);

  function PeriodsToRecords(calendar) {

    setShowPeriodEditor(calendar?.work_schedule?.length === 0);

    if (calendar?.client_records && calendar?.work_schedule.length > 0) {

      let key = 0;
      let result = [];

      //Период от начала до окончания заполняем как занят
      let startTime = DateTime.fromISO(calendar.work_schedule[0].start);
      let endTime = DateTime.fromISO(calendar.work_schedule.at(-1).end);

      for (let time = startTime; time < endTime; time = time.plus({ minutes: calendar?.period })) {
        result[time.valueOf() / 10000] = { index: time.valueOf(), start: time, end: time.plus({ minutes: calendar?.period }), type: "break", key: key };
      }

      //Сверху накладываем расписание мастера
      calendar?.work_schedule.forEach((period, index) => {
        let startTime = DateTime.fromISO(period.start);
        let endTime = DateTime.fromISO(period.end);
        key++;
        for (let time = startTime; time < endTime; time = time.plus({ minutes: calendar?.period })) {
          result[time.valueOf() / 10000] = { index: time.valueOf(), start: time, end: time.plus({ minutes: calendar?.period }), type: "free", key: key };
        }
      });

      //Сверху накладываем записи клиентов
      calendar?.client_records.forEach(period => {
        let startTime = DateTime.fromISO(period.start);
        let endTime = DateTime.fromISO(period.end);
        key++;
        for (let time = startTime; time < endTime; time = time.plus({ minutes: calendar?.period })) {
          result[time.valueOf() / 10000] = { client_record: period, index: time.valueOf(), start: time, end: time.plus({ minutes: calendar?.period }), type: "record", key: key };
        }
      });


      // переиндексируем
      let result_reindex = [];
      for (let index in result) {
        result_reindex.push(result[index]);
      }

      // сортируем
      let result_sort = result_reindex.sort((a, b) => a.index - b.index);

      //группируем однотипные периоды
      let group = {};
      let result_group = [];

      result_sort.forEach((item) => {

        if (group?.key !== item?.key) {//Новая группа

          if (group?.periods?.length > 0) {//Если группа заполнена, сохраняем
            result_group.push(group);
          }
          group = { ...item, periods: [item.start] };
        } else {//добавляем период
          group.periods.push(item.start);
        }

      });

      if (group?.periods?.length > 0) {  //Если группа заполнена, сохраняем
        result_group.push(group);
      }

      return result_group;
      //setCalendarRecords(result_group);


    } else {
      return [];
      //setCalendarRecords([]);
    }

  };




  return (
    <>

      <AdminPanel />
      <AdminMenu buttonText="Записать клиента" handleButtonClick={() => navigate("/app/master/tenant/clients/")} />

      <Container className="admin mt-2">

        <CalendarHeader calendarDate={calendarDate} handleUpdateCalendarDate={setCalendarDate} />


        <div className="calendar">
          {calendarRecords?.map((record, index) => (
            <div
              className={"item " + record.type + " " + (record?.client_record?.status ? record?.client_record?.status : "")}
              key={index}

            >
              <Row className="p-2">
                <Col className="col-2 pe-0"
                  onClick={() => (record?.client_record?.guid ? navigate("/app/master/tenant/record/" + record?.client_record.guid + "/") : false)}
                >
                  {record?.periods.map((time, index2) =>
                    (<span key={index2} className="period">{DateTime.fromISO(time).toFormat("H:mm")}</span>)
                  )}
                </Col >
                <Col className="col-8 ps-1 pe-0"
                  onClick={() => (record?.client_record?.guid ? navigate("/app/master/tenant/record/" + record?.client_record.guid + "/") : false)}
                >
                  {record?.type === "free" && (
                    <span className="period-status"
                      onClick={() => PopUpAlert('PopUpRecordFree')}
                    >Свободно</span>
                  )}

                  {record?.type === "break" && (
                    <span className="period-status"
                      onClick={() => PopUpAlert('PopUpRecordBreak')}
                    >Перерыв</span>
                  )}

                  {record?.type === "record" && (
                    <>
                      <span className="client-name">{record?.client_record.client}</span>
                      <div>
                        {DateTime.fromISO(record.client_record.start).toFormat("H:mm")}
                        &nbsp;-&nbsp;
                        {DateTime.fromISO(record.client_record.end).toFormat("H:mm")}
                      </div>

                      {calendar?.client_records_detail?.map((detail, index3) => (
                        record?.client_record.guid === detail.guid && (
                          <div key={index3} className={"detail"}>
                            {detail.name}
                            {/* {detail.price}₽ */}
                          </div>
                        )
                      ))}

                      {record?.client_record.status &&
                        (<span className={'pt-1 status-name ' + record?.client_record.status} >
                          {StatusName[record?.client_record.status]}
                        </span>)
                      }

                    </>
                  )}
                </Col >
                <Col className="col-2 text-end">
                  {record?.client_record?.status &&
                    (<span className={'status-icon ' + record.client_record.status}
                      onClick={() => PopUpAlert('PopUpRecordStatus-' + record?.client_record.status)}
                    ></span>)
                  }
                </Col>
              </Row >
            </div>
          ))}
        </div>


        {calendar ? (
          calendarDate >= DateTime.now().plus({ milliseconds: appData.time_zone_delta }).startOf('day') && (
            <Row className="text-center period-editor">
              <Col>
                {
                  showPeriodEditor ? (
                    <AdminPeriodEditor calendar={calendar} handleCancel={() => setShowPeriodEditor(false)} handleUpdateCalendarDate={setCalendarDate} />
                  ) : (
                    <Button variant={"outline"} className="mt-3" onClick={() => setShowPeriodEditor(true)}>Редактировать часы работы</Button>
                  )}
              </Col >
            </Row >
          )
        ) : (
          <Row className="vh-100 text-center">
            <Col className="mt-5">
              <Spinner animation="border" variant="secondary" />
            </Col>
          </Row>
        )}

      </Container >
    </>
  );
};



export default AdminCalendar; 