import { NavLink } from "react-router-dom";
import { Button, Navbar, Nav, Container } from "react-bootstrap";

import "./Style.css";

const AdminMenu = (params) => {
    let { handleButtonClick, buttonText, title } = params;
    return (
        <Navbar expand="lg" className="mt-2 mb-2" >
            <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />

                {title && (
                    <h1 style={{ width: "calc(100% - 55px)", paddingRight: "40px", margin: "0px" }}>{title}</h1>
                )}

                {buttonText && (
                    <Button variant="base" style={{ width: "calc(100% - 55px)" }} onClick={handleButtonClick}>{buttonText}</Button>
                )}

                {/* <NavLink className={"btn btn-base"} style={{ width: "calc(100% - 55px)" }} to={"/app/master/tenant/clients/"}>Записать клиента</NavLink> */}

                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">

                        <NavLink className={"nav-link  mt-2"} to={"/app/master/tenant/"} end >Журнал записей</NavLink>

                        <NavLink className={"nav-link"} to={"/app/master/tenant/settings/"} end >Настройки бота</NavLink>

                        {/* <NavLink className={"nav-link"} to={"/app/master/tenant/tariff/"} end >Тарифы</NavLink> */}

                        <NavLink className={"nav-link"} to={"/app/master/tenant/catalog/"} end >Мои услуги</NavLink>

                        <NavLink className={"nav-link"} to={"/app/master/tenant/clients/"} end >Мои клиенты</NavLink>

                        <NavLink className={"nav-link"} to={"/app/master/tenant/manual_mailings/"} end >Отправка сообщений клиентам</NavLink>

                        <NavLink className={"nav-link"} to={"/app/master/tenant/automatic_mailings/"} end >Автоматические рассылки</NavLink>

                        <NavLink className={"nav-link"} to={"/app/master/tenant/direct_link/"} end >Кнопка ссылка на бот</NavLink>
                        <NavLink className={"nav-link"} to={"/app/master/tenant/help/"} end >Помощь</NavLink>

                    </Nav>

                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default AdminMenu;
