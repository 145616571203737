import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { AppContext } from "../../App.js";
import { Container, Row, Col, Accordion, Spinner } from 'react-bootstrap';
import AdminPanel from "../../components/AdminPanel/AdminPanel.js";
import { useLocation, useNavigate, NavLink } from "react-router-dom";
import { LandingManicure, LandingHairdresser, LandingBrovist, LandingVisagiste, LandingCosmetologist, LandingMassage, LandingGroomer, LandingTelegram } from "./icons.js";
import { MyZamLogo } from "../../components/Icons.js";

import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/700.css";
import "./Style.css";

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import "./slider/style.css";
import { Zoom, Navigation, Pagination } from 'swiper/modules';


const Landing = (props) => {

  const [tariffs, setTariffs] = useState();
  const [faq, setFaq] = useState();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {

    const handleBackButton = () => {
      navigate(-1);
    }

    if (location?.state?.back) {

      window?.Telegram.WebApp.BackButton.show();
      window?.Telegram.WebApp.BackButton.onClick(handleBackButton);

      return () => {
        window?.Telegram.WebApp.BackButton.hide();
        window?.Telegram.WebApp.BackButton.offClick(handleBackButton);
      };
    }

  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
    axios.get("/api/public_v1/tariffs/").then((result) => {
      setTariffs(result.data);
    });
  }, []);

  useEffect(() => {
    axios.get("/api/public_v1/landing_faq/").then((result) => {
      setFaq(result.data);

    });
  }, []);



  return (
    <Container className="landing" >

      <Row className="step-1">
        <Col className="mt-5">
          <MyZamLogo />
          <h1 className="mt-4">Сервис для онлайн записи клиентов к частному мастеру</h1>
          <div className="img-step-1"></div>

          <h2 className="mt-4 mb-4">Создан специально для мастеров Beauty-индустрии</h2>
          {/* <p className="mb-4">Клиенты видят в Telegram ваши услуги, график работы и самостоятельно записываются!</p>
          <a href="https://t.me/reg_new_master_bot" className="btn-yellow">Воспользоваться сервисом</a> */}
          {/* <h1 className="mt-5 mb-4">Кому подходит?</h1> */}

          <div className="for-whom">
            <LandingManicure />
            <span className="for-whom-text">Мастер маникюра
            </span>
          </div>

          <div className="for-whom">
            <LandingHairdresser />
            <span className="for-whom-text">Парикмахер
            </span>
          </div>

          <div className="for-whom">
            <LandingBrovist />
            <span className="for-whom-text">Бровист/ лэшмейкер
            </span>
          </div>

          <div className="for-whom">
            <LandingVisagiste />
            <span className="for-whom-text">Визажист
            </span>
          </div>

          <div className="for-whom">
            <LandingCosmetologist />
            <span className="for-whom-text">Косметолог
            </span>
          </div>

          <div className="for-whom">
            <LandingMassage />
            <span className="for-whom-text">Массажист
            </span>
          </div>

          <div className="for-whom mb-5">
            <LandingGroomer />
            <span className="for-whom-text">Грумер
            </span>
          </div>
        </Col>
      </Row>
      {/* 
      <Row className="step-2 mt-5">
        <Col >
          <h1>Как работает?</h1>
          <LandingTelegram className="d-block mt-4 mb-3" />
          <h4 className="mb-4">Вы подключаетесь к сервису Мой Зам и получаете собственное приложение-бот в Telegram, красивое, простое и понятное</h4>
          <Swiper
            modules={[Zoom, Pagination]}
            zoom={true}
            slidesPerView={'auto'}
            spaceBetween={20}
            pagination={{
              clickable: true,
            }}
            className="mb-4"
          >
            <SwiperSlide ><div className="slide"></div></SwiperSlide>
            <SwiperSlide><div className="slide"></div></SwiperSlide>
            <SwiperSlide><div className="slide"></div></SwiperSlide>
            <SwiperSlide><div className="slide"></div></SwiperSlide>
            <SwiperSlide><div className="slide"></div></SwiperSlide>
            <SwiperSlide><div className="slide"></div></SwiperSlide>
            <SwiperSlide><div className="slide"></div></SwiperSlide>
          </Swiper>
          <a className="btn-yellow" href="https://t.me/reg_new_master_bot">Воспользоваться сервисом</a>
        </Col>
      </Row> */}

      <Row className="step-3  mt-5">
        <Col >
          <h1>Что получите вы?</h1>
          <div className="img-step-3"></div>
          <p className="type-1">Современный и удобный инструмент для работы и коммуникаций с клиентами</p>
          <p className="type-1">Улучшение посещаемости и плотности записи за счёт:</p>
          <p className="type-2">рассылок клиентам, автоматических напоминаний и уведомлений;</p>
          <p className="type-2">функционала Лист ожидания.</p>
          <p className="type-1">Привлечение новых клиентов в свой Telegram-бот:</p>
          <p className="type-2">из социальных сетей, Яндекс-карт, 2ГИС, Google-map</p>
          <p className="type-2">из своих групп и каналов в Telegram, из других мессенджеров, домовых, районных, городских групп, каналов и чатов</p>
          <h1 className="mb-3 mt-5">Что получат клиенты?</h1>
          <p className="type-2">Каталог ваших услуг с красивыми фото и подробным описанием</p>
          <p className="type-2">Постоянный 24/7 доступ к вашему расписанию и возможность записаться самостоятельно</p>
          <p className="type-2 mb-3">Подсказки при записи, напоминания о визите, предложения записаться снова</p>
          <a href="https://t.me/reg_new_master_bot" className="btn-yellow mb-5">Воспользоваться сервисом</a>
        </Col>
      </Row>

      <Row className="step-4">
        <Col >
          <h1 className="mt-5 mb-4">Сколько это стоит?</h1>
          <div className="img-step-4 mb-3"></div>
          <h2>Все возможности сервиса в первые 14 дней бесплатно!</h2>
          <p className="type-3">Далее выбирайте необходимый для себя функционал и тариф</p>
          <p className="type-4 mt-1 mb-4"> Тариф можно менять</p>
          <Swiper
            modules={[Zoom, Pagination]}
            zoom={true}
            slidesPerView={'auto'}
            pagination={{
              clickable: true,
            }}
          >
            {tariffs?.map((item) => (
              <SwiperSlide >
                <div className={"slide-price slide-type-" + item.code}>
                  <div className="slide-header">
                    <div className="slide-header-title">{item.name}</div>
                    <div className="slide-header-price">{item.price === 0 ? "бесплатно" : (item.price + " руб./мес.")}</div>
                  </div>
                  <div className="slide-options">
                    {item?.options?.map((option) => (
                      <p className={"slide-option-" + option.active}>{option.name}</p>
                    ))}

                  </div>

                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <a href="https://t.me/reg_new_master_bot" className="btn-yellow mt-3 mb-5">Пробовать бесплатно</a>
        </Col>
      </Row>

      <Row className="step-5 mt-5">
        <Col>
          <h1 className="mb-3">Как подключиться к Мой Зам?</h1>
          <div className="img-step-5 mb-3"></div>
          <h2 className="mb-3">Для подключения нужно зарегистрировать свой бот в Telegram и привязать его к сервису Мой Зам</h2>
          <p className="type-5">Сделать это просто, вам потребуется всего несколько минут</p>
          <p className="type-6 mt-3 mb-4">В процессе подключения вы увидите подсказки</p>
          <a href="https://t.me/reg_new_master_bot" className="btn-yellow">Воспользоваться сервисом</a>
        </Col>
      </Row>

      <Row className="step-6 mt-5">
        <Col>
          <h1>Вопросы-ответы</h1>
          <Accordion className="faq" >
            {faq?.map((item, index) => (
              <Accordion.Item key={index} eventKey={index}>
                <Accordion.Header><span>{item.name}</span></Accordion.Header>
                <Accordion.Body >{item.template}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Col>
      </Row>

      <Row className="step-7 mt-5">
        <Col>
          <h1>Остались ещё вопросы? Напишите нам!</h1>
          <div className="img-step-7"></div>
          <a href="https://t.me/MyZamSupport" className="btn-frame mb-5">Отправить сообщение</a>
        </Col>
      </Row>

      <Row className="footer">
        <Col>
          <MyZamLogo className="logo" />
          <NavLink className="navigate" to={"/app/doc/privacy-policy/"} end >Политика конфиденциальности</NavLink>
          <NavLink className="navigate" to={"/app/doc/offer-agreement/"} end >Договор оферты</NavLink>
          <NavLink className="navigate" to={"/app/doc/contacts/"} end >Контакты</NavLink>
          <div className="copyright">© 2024 Мой Зам</div>
        </Col>
      </Row>

    </Container >
  );
};

export default Landing;
