import { useEffect, useState, createContext } from "react";

// import Settings from "./appMaster/AdminSettings/AdminSettings";
// import Basket from "./appBasket/Basket";
// import BasketDelivery from "./appBasketDelivery/Delivery";
// import BasketPayment from "./appBasketPayment/Payment";
import TelegramAuth from "./components/TelegramAuth/TelegramAuth";

import { useLocation, Route, Routes } from "react-router-dom";
// import "@fontsource/inter";

// import "@fontsource/inter/inter";
// import "./App.css";

import ClientCatalog from "./appMaster/ClientCatalog/ClientCatalog";
import ClientCalendar from "./appMaster/ClientCalendar/ClientCalendar";
import ClientRecords from "./appMaster/ClientRecords/ClientRecords";

import AdminCalendar from "./appMaster/AdminCalendar/AdminCalendar";
import AdminClientRecord from "./appMaster/AdminCalendar/AdminClientRecord";
import AdminSettings from "./appMaster/AdminSettings/AdminSettings";
import AdminTariff from "./appMaster/AdminTariff/AdminTariff";
import AdminCatalog from "./appMaster/AdminCatalog/AdminCatalog";
import AdminItem from "./appMaster/AdminCatalog/AdminItem";
import AdminManualMailings from "./appMaster/AdminManualMailings/AdminManualMailings";
import AdminAutomaticMailingsCatalog from "./appMaster/AdminAutomaticMailings/AutomaticMailingsCatalog";
import AdminAutomaticMailingsItem from "./appMaster/AdminAutomaticMailings/AutomaticMailingItem";
import AdminBotLinks from "./appMaster/AdminBotLinks/AdminBotLinks";
import AdminClients from "./appMaster/AdminClients/AdminClients";
import AdminClientItem from "./appMaster/AdminClients/AdminClientItem";
import AdminHelp from "./appMaster/AdminHelp/AdminHelp";
import Landing from "./appMaster/Landing/Landing";
import Doc from "./appMaster/Doc/Doc";


import "@fontsource/jost";
import "@fontsource/jost/500.css";

import "./Themes.css";

// import Info from "./appInfo/info";
// import InfoDetail from "./appInfo/infoDetail";

const WebApp = window.Telegram.WebApp;
if (WebApp?.initData) {
  let headerColor = getComputedStyle(document.documentElement).getPropertyValue(
    "--theme-header-color"
  );
  //WebApp.setHeaderColor(headerColor);
}

//+++костыль от смахивания в ТГ

// window.addEventListener("scroll", function (event) {

//   // const target = event.currentTarget;
//   // const isScrolled = target.scrollTop + target.clientHeight + 1 >= target.scrollHeight;
//   // console.log("isScrolled", isScrolled);

//   if (window.scrollY === 0) {
//     window.scrollTo(0, 1);
//     // console.log("window.scrollTo(0, 1)");
//     event.stopPropagation();
//   }

// });



//---костыль от смахивания в ТГ


export let StatusName = {
  "created": "Ожидание клиента",
  "not_confirmed": "Не подтверждено",
  "confirmed": "Клиент подтвердил приход",
  "completed": "Клиент пришел",
  "canceled": "Запись отменена",
  "skipped": "Клиент не пришел",
};



export const AppContext = createContext();

export const IsoNoZone = "yyyy-MM-dd'T'HH:mm:ss";

const App = () => {

  let location = useLocation();

  // Важные изменения страницы 
  // https://yandex.ru/support/metrica/code/counter-spa-setup.html
  useEffect(() => {
    window?.ym(98057496, 'hit', location?.pathname);
  }, [location]);


  const [appData, setAppData] = useState();

  return (
    <AppContext.Provider value={[appData, setAppData]} >

      <Routes>

        <Route path="/app/:guid/" element={<TelegramAuth />} />
        <Route path="/app/tgauth/:guid/" element={<TelegramAuth />} />

        <Route path="/app/landing/" element={<Landing />} />
        <Route path="/app/doc/:id/" element={<Doc />} />

        <Route path="/app/master/client/" element={<ClientCatalog />} />
        <Route path="/app/master/client/calendar/" element={<ClientCalendar />} />
        <Route path="/app/master/client/records/" element={<ClientRecords />} />

        <Route path="/app/master/tenant/" element={<AdminCalendar />} />
        <Route path="/app/master/tenant/calendar/:date/" element={<AdminCalendar />} />
        <Route path="/app/master/tenant/record/:guid/" element={<AdminClientRecord />} />
        <Route path="/app/master/tenant/settings/" element={<AdminSettings />} />
        <Route path="/app/master/tenant/tariff/" element={<AdminTariff />} />
        <Route path="/app/master/tenant/catalog/" element={<AdminCatalog />} />
        <Route path="/app/master/tenant/catalog/item/:guid/" element={<AdminItem />} />
        <Route path="/app/master/tenant/clients/" element={<AdminClients />} />
        <Route path="/app/master/tenant/clients/item/:guid/" element={<AdminClientItem />} />
        <Route path="/app/master/tenant/manual_mailings/" element={<AdminManualMailings />} />
        <Route path="/app/master/tenant/automatic_mailings/" element={<AdminAutomaticMailingsCatalog />} />
        <Route path="/app/master/tenant/automatic_mailings/:guid/" element={<AdminAutomaticMailingsItem />} />
        <Route path="/app/master/tenant/direct_link/" element={<AdminBotLinks />} />
        <Route path="/app/master/tenant/help/" element={<AdminHelp />} />

      </Routes>
    </AppContext.Provider >
  );
};

export default App; 
